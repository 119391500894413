.modal-overlay {position: fixed;inset: 0;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}
.modal-content {background-color: white;border-radius: 8px;padding: 24px;width: 100%;max-width: 400px;}
.modal-title {font-size: 1.25rem;font-weight: 600;margin-bottom: 16px;}
.modal-form {display: flex;flex-direction: column;gap: 16px;align-items: flex-start;padding: 20px;}
.form-group {margin-bottom: 16px;}
.form-label {font-weight: 500;display: block;margin-bottom: 8px;}  
/* Checkbox Group */
.checkbox-group {display: flex;flex-direction: column;}  
.checkbox-label {display: flex;align-items: center;}  
.checkbox-input {margin-right: 8px;}
.text-area, .file-input, .url-input {width: 100%;padding: 8px;border-radius: 4px;border: 1px solid #ddd;}
.text-area {height: 100px;}
.button-group {display: flex;justify-content: flex-end;gap: 8px;}
.modal-form .cancel-button {padding: 8px 16px;background-color: transparent;border: 1px solid #ddd;border-radius: 4px;cursor: pointer;}  
.modal-form .submit-button {padding: 8px 16px;background-color: #000;color: white;border: none;border-radius: 4px;cursor: pointer;}
.modal-form input[type="checkbox"]{margin: 0;margin-right: 10px;}