div.countryWithPhonenumber {    margin-top: 0;display: flex;max-width: 50%;border: 1px solid grey;border-radius: 5px;}
div.countryWithPhonenumber input{border:none; outline:none;padding:10px 0;                          }
select#country-code {background: transparent;color: #000;padding: 10px;width: 28%;border-right: 1px solid;}
.countryWithPhonenumber {flex: 0 0 50%!important;}
input#phone-number {flex: 0 0 100%;max-width: 72%;padding: 10px;}
.pasword-block{margin-top: 30px;}
.pasword-popup {position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background-color: white;box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);padding: 80px 70px;display: none;opacity: 0;transition: opacity 0.4s ease-in-out;z-index: 9999;border-radius: 18px;}
.pasword-popup.visible {display: block;opacity: 1;}
.pswd-form input {margin-bottom: 10px;padding: 8px;width: 100%;box-sizing: border-box;}
.accessButton.password button {padding: 8px 16px;background-color: #007bff;color: white;border: none;cursor: pointer;}
.accessButton.password button:hover {background-color: #0056b3;}
.pswd-form input {border: 1px solid grey;border-radius: 8px;margin: 10px auto;max-width: 80%;padding: 8px 12px;}
.pswd-form {text-align: center;}
small.error {text-align: center;margin: 5px 0 5px auto;color: red;width: 50%;}
button.close-popup {position: absolute;top: 8px;right: 30px;font-size: 54px;background: transparent;border: none;color: #000;cursor: pointer;}