button.campaign-btn {padding: 10px 20px;border-radius: 8px;transition: 0.3s;margin: 0 auto;max-width: 80%;}
button.campaign-btn:hover {transform: scale(1.10);}
button.create-campaign-btn{margin-bottom: 30px;transition: .3s;}
.campaign-setup-inner input, .campaign-setup-inner select {width: 100%;margin: 10px 0 22px;padding: 10px 20px;box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);border-radius: 8px;}
.campaign-setup-inner input:focus{border: 1px solid #000;}
.campaign-setup-inner label{font-size: 15px;font-weight: bold;color: #000;}
button.start-button {background: #41f1b6;color: #fff;padding: 5px;border-radius: 5px;font-size: 10px}
button.stop-button {background: #ff7782;color: #fff;padding: 5px;border-radius: 5px;font-size: 10px;}
button.start-button:hover, button.stop-button:hover {transform: scale(1.10);}
.campaign-table {margin-top: 40px}
.campaign-setup-inner {display: flex;flex-direction: column}
.campaign-setup-inner {background: #fff;padding: 40px;border-radius: 12px;max-width: 60%;margin: 0 auto;box-shadow: var(--box-shadow);}
.button-group {display: flex;justify-content: space-between;margin-top: 30px;}
button.pagination-button {padding: 10px 20px;background: #e9e9e9;border-radius: 8px;width: 100px;}
button.pagination-button.active {padding: 10px 20px;pointer-events: none;border-radius: 8px;width: 100px;background-color: transparent;}