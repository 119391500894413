input.searchInput {width: 40%;padding: 10px 20px;border-radius: 8px;box-shadow: 1px 1px 1px #000;}
button.creditButton {width: 90%;background: transparent;}
.popup-content {margin-top: 40px;text-align: center;}
.popup-content h3 {font-size: 24px}
.popup-content input {width: 30%;padding: 10px 20px;border-radius: 8px;margin-top: 20px}
.popup-content button {padding: 10px 20px;margin: 20px 5px;border-radius: 8px;}
.topBar {display: flex;justify-content: space-between;padding: 0 20px 30px 0;}
button.createUserButton {padding: 10px 20px;border-radius: 8px;}
button.createUserButton:hover {transform: scale(1.05);}
.top-bar-search {flex: 0 0 70%;max-width: 68%;}
button.SearchButton {padding: 12px 20px;border-radius: 12px;margin-left: 15px;}
.pagination {display: flex;justify-content: space-around;margin-top: 30px;}
.backtouser{margin-top: 30px;background: transparent;border: 1px solid rgb(199, 195, 195);padding: 8px 12px;border-radius: 8px;}
.backtouser:hover{transform: scale(1.10);}
.action-button.success{background: #41f1b6;color: #fff;padding: 5px;border-radius: 5px;font-size: 10px;}
.action-button.danger{background: #ff7782;color: #fff;padding: 5px;border-radius: 5px;font-size: 10px;}
p.userprofileTable{margin-bottom: 30px}