.modal-overlay {position: fixed;inset: 0;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}
.modal-content {background-color: white;border-radius: 8px;padding: 24px;width: 100%;}
.modal-title {font-size: 1.25rem;font-weight: 600;margin-bottom: 16px;}
.knowledgebase-table {width: 100%;border-collapse: collapse;margin-bottom: 16px;}
.table-header {text-align: left;border-bottom: 2px solid #ddd;}
.table-header th {padding: 8px;text-align: center;}  
.table-row {border-bottom: 1px solid #eee;}
.table-row td {padding: 8px;}
.action-column {text-align: right;}
.download-btn {border: 1px solid #ddd;background-color: transparent;padding: 4px 8px;border-radius: 4px;cursor: pointer;}  
.modal-footer {margin-top: 16px;text-align: right;}
.modal-footer .close-btn {padding: 8px 16px;background-color: #000;color: white;border: none;border-radius: 4px;cursor: pointer;}