.edit-users-input  {display: flex;justify-content: space-between;align-items: center;width: 98%;flex-wrap: wrap;margin-bottom: 20px;}
.edit-users-input  div {flex: 0 0 50%;max-width: 42%;margin-top: 32px;}
.eu-inputs input {background: transparent;padding: 10px 15px;border: 1px solid grey;border-radius: 5px;}
.eu-inputs {display: flex;justify-content: space-between;align-items: center;}
.eu-inputs label{flex: 0 0 30%;max-width: 30%;}
.eu-inputs input {flex: 0 0 80%;max-width: 75%;}
.profileInner label {font-size: 16px;}
.topBar.edituser{justify-content: flex-start;align-items: center;gap: 20px;}
.topBar.edituser .top-bar-search{flex: 0 0 20%;}
.top-bar-search input{width: 200px;}
button.editButton{background: transparent;}
button.editButton:hover{color: red;}
.edit-buttons {display: flex;justify-content: space-between;padding: 0 30px;margin-top: 30px;}