.chat-overlay {position: fixed;inset: 0;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}
.chat-container {background-color: white;border-radius: 8px;width: 100%;max-width: 400px;display: flex;flex-direction: column;height: 600px;position: relative;}
.chat-header {padding: 16px;border-bottom: 1px solid #ddd;display: flex;justify-content: space-between;align-items: center;}  
.chat-header h3 {font-size: 1.125rem;font-weight: 600;margin: 0;}  
.chat-close-icon {background: none;border: none;font-size: 3rem;cursor: pointer;color: #666;}  
.chat-close-icon:hover {color: #000;}  
.chat-messages {flex-grow: 1;padding: 16px;overflow-y: auto;display: flex;flex-direction: column;}
.chat-message {margin-bottom: 16px;  }
.chat-user {text-align: right;}
.chat-bot {text-align: left;}
.chat-bubble {display: inline-block;padding: 8px;border-radius: 8px;background-color: #f0f0f0;}
.chat-user .chat-bubble {background-color: #cce4ff}
.chat-input-container {padding: 16px;border-top: 1px solid #ddd;display: flex;}
.chat-input {flex-grow: 1;margin-right: 8px;padding: 8px;border-radius: 4px;border: 1px solid #ddd;}
.chat-send-button {padding: 8px 16px;background-color: #000;color: white;border: none;border-radius: 4px;cursor: pointer;}