@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --color-primary: #7380ec;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #ffffff;
    --color-info-dark: #7d8da1;
    --color-info-light: #dce1eb;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-primary-variant: #111e88;
    --color-dark-variant: #677483;
    --color-backgorund: #f6f6f9;
    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;
    --card-padding: 1.8rem;
    --padding-1: 1.2rem;
    --box-shadow: 0 2rem 3rem var(--color-light);
}
/* DARK THEME VARIABLE */
.dark-theme-variables {
    --color-backgorund: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --box-shadow: 0 2rem 3rem var(--color-light);}
* {margin: 0;padding: 0;outline: 0;appearance: none;border: 0;text-decoration: none;list-style: none;box-sizing: border-box;}
html {font-size: 14px;}
body {width: 100vw;height: 100vh;font-family: "Nunito", sans-serif;font-size: 0.88rem;background-color: var(--color-backgorund);user-select: none;overflow-x: hidden;color: var(--color-dark);}
a {color: var(--color-dark);}
input {color: var(--color-dark);}
body.dark-theme-variables select#country-code{color:#fff!important;background: #181a1e;}
button.creditButton{color:var(--color-dark)}
img {display: block;width: 100%;height: 100%;}
body.dark-theme-variables button.backtouser{color:#fff}
body.dark-theme-variables input[type="checkbox"]{border-color: #fff;}
h1 {font-weight: 800;font-size: 2.5rem;}
h2 {font-size: 1.4rem;font-weight: 800;margin-bottom: 20px;}
h3 {font-size: 0.87rem;}
h4 {font-size: 0.8rem;}h5 {font-size: 0.77rem;}
small {font-size: 0.75rem;}
.profile-photo {width: 2.8rem;height: 2.8rem;overflow: hidden;border-radius: 50%;}
.text-muted {color: var(--color-info-dark);}
p {color: var(--color-dark-variant);}
b {color: var(--color-dark);}
.primary {    color: var(--color-primary);}
.danger {color: var(--color-danger);}
.success {color: var(--color-success);}
.warning {color: var(--color-warning);}

/* DASHBOARD CSS */
.Board-container {
display: grid;width: 98%;gap: 1.8rem;grid-template-columns: 14rem auto 23rem}
aside {height: 100vh;background-color: #202528}
aside .top {background: white;display: flex;align-items: center;justify-content: space-between;margin-top: 1.4rem;}
aside .close {display: none;}
/* SIDEBAR */
aside .sidebar {display: flex;flex-direction: column;height: 86vh;position: relative;top: 3rem;}
aside h3 {font-weight: 500}
aside .sidebar a {display: flex;color: var(--color-info-dark);margin-left: 2rem;gap: 1rem;align-items: center;position: relative;height: 3.7rem;transition: all 300ms ease;}
aside .sidebar a:last-child {position: absolute;bottom: 2rem;width: 100%;}
aside .sidebar a span {font-size: 1.6rem;transition: all 300ms ease;}
aside .sidebar a.active {background: var(--color-light);color: var(--color-primary);margin-left: 0;}
aside .sidebar a.active::before {content: "";width: 6px;height: 100%;background: var(--color-primary);}
aside .sidebar a:hover {color: var(--color-primary);}
aside .sidebar a.active span {color: var(--color-primary);margin-left: calc(1rem - 3px);}
aside .sidebar a:hover span {margin-left: 1rem}
aside .sidebar .message-count {background: var(--color-danger);color: var(--color-white);padding: 2px 10px;font-size: 11px;border-radius: var(--border-radius-1);}
/* ****************** MAIN  ********************* */
main {margin-top: 1.4rem;}
main .date {display: inline-block;background: var(--color-light);border-radius: var(--border-radius-1);margin-top: 1rem;padding: 0.5rem 1.6rem;}
main .date input[type="date"] {background: transparent;color: var(--color-dark);}
main .insights {display: grid;grid-template-columns: repeat(3, 1fr);gap: 1.6rem;}
main .insights>div, .campaign-setup>div {background: var(--color-white);padding: var(--card-padding);border-radius: var(--card-border-radius);margin-top: 1rem;box-shadow: var(--box-shadow);transition: all 300ms ease;}
main .insights>div:hover, .campaign-setup>div:hover {box-shadow: none;}
main .insights>div span {background: var(--color-primary);padding: 0.5rem;border-radius: 50%;color: var(--color-white);font-size: 2rem}
main .insights>div.expenses span {background-color: var(--color-danger);}
main .insights>div.accountBal span {background: var(--color-success);}
main .insights>div .middle {display: flex;align-items: center;justify-content: space-between;}
main .insights h3 {margin: 1rem 0 0.6rem;font-size: 1rem}
main .insights .progress {position: relative;width: 92px;height: 92px;border-radius: 50%;}
main .insights svg {width: 7rem;height: 7rem;}
main .insights svg circle {fill: none;stroke: var(--color-primary);stroke-width: 14;stroke-linecap: round;transform: translate(5px, 5px);stroke-dashoffset: 92;stroke-dasharray: 110;}
main .insights .accountBal svg circle {stroke-dashoffset: -30;stroke-dasharray: 200;}
main .insights .expenses svg circle {stroke-dashoffset: 20;stroke-dasharray: 80;}
main .insights .onHoldBal svg circle {stroke-dashoffset: 35;stroke-dasharray: 110;}
main .insights .progress .number {position: absolute;top: 0;left: 0;height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;}
main .insights small {margin-top: 1.3rem;display: block;}
/* HISTORY */
main .history {margin-top: 2rem;}
main .history h2 {margin-bottom: 0.8rem;}
main .history table,
table {background: var(--color-white);width: 100%;border-radius: var(--card-border-radius);padding: var(--card-padding);text-align: center;box-shadow: var(--box-shadow);transition: all 300ms ease;}
main .history table:hover,
table:hover {box-shadow: none;}
main table tbody td,
table tbody td {height: 2.8rem;border-bottom: 1px solid var(--color-light);color: var(--color-dark-variant)}
main table tbody tr:last-child td,
table tbody tr:last-child td {border: none;}
main .history a {text-align: center;display: block;margin: 1rem auto;color: var(--color-primary);}
/* RIGHT SIDE */
.right {margin-top: 1.4rem;}
.right .top {display: flex;justify-content: end;gap: 2rem;}
.right .top button {display: none}
.right .theme-toggler {background: var(--color-light);display: flex;justify-content: space-between;align-items: center;height: 1.6rem;width: 4.2rem;cursor: pointer;border-radius: var(--border-radius-1)}
.right .theme-toggler span {display: flex;align-items: center;justify-content: center;height: 100%;width: 50%;font-size: 1.2rem;}
.right .theme-toggler span.active {background: var(--color-primary);color: white;border-radius: var(--border-radius-1);}
.right .top .profile {display: flex;gap: 2rem;text-align: right}
/* RECENT UPDATES */
.right .recent-updates {margin-top: 1rem;}
.right .recent-updates h2 {margin: 0.8rem;}
.right .recent-updates .updates {background: var(--color-white);padding: var(--card-padding);border-radius: var(--card-border-radius);box-shadow: var(--box-shadow);transition: all 300ms ease;}
.right .recent-updates .updates:hover {box-shadow: none;}
.right .recent-updates .updates .update {display: grid;grid-template-columns: 2.6rem auto;gap: 1rem;margin-bottom: 1rem;}

/* ANALYTICS */
.right .analytics {margin-top: 2rem;}
.right .analytics h2 {margin-bottom: 0.8rem;}
.right .analytics .item {background: var(--color-white);display: flex;align-items: center;gap: 1rem;margin-bottom: 0.7rem;padding: 1.4rem var(--card-padding);border-radius: var(--border-radius-3);box-shadow: var(--box-shadow);transition: all 300ms ease;}
.right .analytics .item:hover {box-shadow: none;}
.right .analytics .item .right {display: flex;justify-content: space-between;align-items: start;margin: 0;width: 100%;}
.right .analytics .item .icon {padding: 0.6rem;color: var(--color-white);border-radius: 50%;background: var(--color-primary);display: flex;}
.right .analytics .item.offline .icon {background: var(--color-danger);}
.right .analytics .item.customers .icon {background: var(--color-success);}
.nextPrev {display: flex;margin-top: 20px;}
/* CHANGED CSS */
button.searchSubmit {margin-left: 20px;padding: 10px 15px;border-radius: 5px;background: #fff;box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;}
/* Basic style for checkbox */
input[type="checkbox"] {appearance: none;width: 16px;height: 16px;border: 2px solid black;border-radius: 2px;cursor: pointer;position: relative;border-radius: 50%;}
input[type="checkbox"]:checked {background-color: #fff}
input[type="checkbox"]:checked::after {content: " ";position: absolute;display: flex;justify-content: center;align-items: center;color: white;left: 2.5px;top: 1.75px;font-weight: bold;height: 8.5px;width: 8px;max-width: 8px;background: #000;border-radius: 50%}
.tableUpr {display: flex;justify-content: space-between;margin-bottom: 20px;align-items: center;padding: 0 20px;}
.tableUpr h2 {margin: 0 !important;}
/* MEDIA SCREEN */
@media(max-width:1140px) {
    .Board-container {width: 98%;grid-template-columns: 7rem auto 23rem;}
    aside .logo {display: none;}
    aside .sidebar h3 {display: none;}
    aside .sidebar a {width: 5.6rem;}
    aside .sidebar a:last-child {position: relative;margin-top: 1.8rem;}
    main .insights {grid-template-columns: 1fr;gap: 0;}
    main .history {width: 94%;position: absolute;left: 50%;transform: translate(-50%);margin: 2rem 0 0 8.8rem;}
    main .history table {width: 83vw;}
}
@media(max-width:969px) {
    aside.show-menu {display: block;transition: all 300ms ease;}
    aside.hide-menu {display: none;transition: all 300ms ease;}
    .Board-container {width: 100%;grid-template-columns: 1fr;}
    aside {position: fixed;left: -100%;background: var(--color-white);width: 18rem;z-index: 3;box-shadow: 1rem 3rem 4rem var(--color-light);height: 100vh;padding-right: var(--card-padding);display: none;animation: showMenu 400ms ease forwards;}
    @keyframes showMenu {
        to {left: 0;}
    }
    aside .logo {display: block;margin-left: 1rem;}
    aside .logo h2 {display: inline;}
    aside .sidebar h3 {display: inline}    
    aside .sidebar a {width: 100%;height: 3.4rem;}
    aside .sidebar a:last-child {position: absolute;bottom: 5rem;}
    aside .close {display: inline-block;cursor: pointer;}
    main {margin-top: 8rem;padding: 0 1rem}
    main .history {position: relative;margin: 3rem 0 0 0;width: 100%;}
    main .history table {width: 100%;margin: 0;}
    .right {width: 94%;margin: 0 auto 4rem;}
    .right .top {position: fixed;top: 0;left: 0;align-items: center;padding: 0 0.8rem;height: 4.6rem;background: var(--color-white);width: 100%;margin: 0;z-index: 2;box-shadow: 0 1rem 1rem var(--color-light);}
    .right .top .theme-toggler {width: 4.4rem;position: absolute;left: 66%;}
    .right .profile .info {display: none}
    .right .top button {display: inline-block;background: transparent;cursor: pointer;color: var(--color-dark);position: absolute;left: 1rem;}
    .right .top button span {font-size: 2rem;}
}
/* Access Manager CSS */
hr {background: #9e9a9a;height: 1.5px;margin-top: 15px}
.accessManagerInputs {margin-top: 20px;}
.accessManagerInputs input {padding: 7px 20px;margin: 10px 5px;background: transparent;border: 1px solid;border-radius: 5px;width: 250px;}
.accessManagerInputs label {font-size: 14px;}
.giveAccessBlock {margin-top: 20px;}
.checkBoxes {margin-bottom: 20px;display: flex;gap: 20px;align-items: normal;}
.checkBoxes label {font-size: 16px;}
.checkBoxes input {margin-top: 3px;border-radius: 50%;}
/* PROFILE PAGE */
.profileInputs {display: flex;justify-content: space-between;align-items: center;width: 98%;flex-wrap: wrap;margin-bottom: 20px;}
.profileInputs div {flex: 0 0 50%;max-width: 42%;margin-top: 32px;flex-wrap: wrap;}
.profileInner input {background: transparent;padding: 10px 15px;border: 1px solid grey;border-radius: 5px;}
.profileInner {display: flex;justify-content: space-between;align-items: center;}
.profileInner label, .profileInner input {flex: 0 0 50%;max-width: 50%;}
.profileInner label {font-size: 16px;}
.accessButton {display: flex;gap: 20px;justify-content: space-between;}
.accessButton button {padding: 10px 25px;border-radius: 5px;background: #fff;box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;transition: transform 0.3s ease, box-shadow 0.3s ease;border: 1px solid rgb(217, 212, 212);}
.accessButton button:hover {transform: scale(1.1);box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;}
.accessButton button:active {transform: scale(0.98);box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;}
.accessButton.right{justify-content: end;}
/* HOME */
.true {color: #41f1b6;}
.false {color: #ff7782;}
.DownloadLogs{background: transparent;color: grey;text-decoration: underline;}
.DownloadLogs:hover{color: red;}
 @media(max-width:767px){
    .table-container {overflow-x: auto;width: calc(100vw - 30px);}
}