.wizard-container {margin: 20px;padding: 20px;}  
.step-title {font-size: 1.25rem;font-weight: bold;}
.step-content {margin-top: 20px;}
.input-label {font-size: 0.875rem;color: #4b5563;margin-bottom: 5px;}
.input_field, .textarea-field, .file-input, input[type="url"] {width: 100%;padding: 8px;margin-top: 8px;border: 1px solid #e5e7eb;border-radius: 4px;max-width: 500px;margin-bottom: 20px;}
.radio-group {display: flex;gap: 16px;margin-top: 8px;}
.radio-input {margin-right: 8px;  }
.radio-label {font-size: 0.875rem;color: #4b5563;}
.loading-step {display: flex;flex-direction: column;align-items: center;justify-content: center;margin-top: 20px;}
.loading-spinner {width: 30px;height: 30px;border: 4px solid #e5e7eb;border-top-color: #3b82f6;border-radius: 50%;animation: spin 1s linear infinite;}
  @keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
.button-group {display: flex;justify-content: flex-end;gap: 16px;margin-top: 20px;}
.back-button, .next-button, .submit-button {padding: 8px 16px;border-radius: 4px;font-size: 0.875rem;font-weight: bold;}
.back-button {background-color: #e5e7eb;color: #1f2937;}
.next-button {background-color: #3b82f6;color: white;}
.submit-button {background-color: #10b981;color: white;}
.checkbox-inner {display: flex;gap: 12px;margin: 5px 0;}
.checkbox-inner input[type="checkbox"]{margin: 0;margin-right: 10px;}