.chat-container h2{text-align: center;}

/* General styling for chat container */
.chat_container {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Chat box area */
.chat-box {
  height: 300px;
  overflow-y: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

/* User and AI messages */
.user-message, .ai-message {
  padding: 8px 12px;
  margin: 6px 0;
  border-radius: 6px;
  font-size: 14px;
  max-width: 80%;
}

.user-message {
  background-color: #d4edda;
  color: #155724;
  align-self: flex-end;
}

.ai-message {
  background-color: #d1ecf1;
  color: #0c5460;
  align-self: flex-start;
}

/* User audio message */
.user-audio-message {
  display: flex;
  justify-content: flex-end;
  margin: 6px 0;
}

/* Input container */
.input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

/* Text input */
.text-input {
  flex: 1;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  outline: none;
  resize: none;
  font-size: 14px;
  height: 40px;
}

/* Send button */
.send-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  transition: background 0.3s ease;
}

.send-button:hover {
  background-color: #45a049;
}

/* Controls section */
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

/* Microphone button */
.mic-button {
  background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 14px;
    transition: background 0.3s ease;
    height: 40px;
    width: 40px;
    display: flex
;
    justify-content: center;
    align-items: center;
}

.mic-button:hover {
  background-color: #0056b3;
}

/* Voice selector dropdown */
.voice-selector {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

/* Toggle switch container */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  cursor: pointer;
}

/* Toggle switch input */
.toggle-input {
  display: none;
}

/* Toggle switch slider */
.toggle-slider {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  transition: background 0.3s ease;
}

.toggle-slider::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 2px;
  transition: transform 0.3s ease;
}

/* When the toggle is checked */
.toggle-input:checked + .toggle-slider {
  background-color: #4CAF50;
}

.toggle-input:checked + .toggle-slider::before {
  transform: translateX(20px);
}

/* Toggle label */
.toggle-label {
  color: #333;
}

/* Responsive styles */
@media (max-width: 600px) {
  .chat-container {
    max-width: 90%;
  }

  .input-container {
    flex-direction: column;
  }

  .text-input {
    width: 100%;
  }

  .send-button, .mic-button {
    width: 100%;
  }
}



.user-message{margin-left: auto}
.AI_message, .USER_message{font-weight: bold;}