body {background-color: #8cc8a2;}
.body-inner {margin-left: -30px;margin-top: 40px;}
.backg {position: absolute;}
.login {height: 220px;width: 280px;background-color: white;position: relative;top: 190px;left: 25px;padding-left: 30px;box-sizing: border-box;z-index: 1;padding-top: 50px;}
.login input {padding: 8px 20px;border: none;background: transparent;border-bottom: 1px solid;width: 88%;}
.frm {position: relative;top: 50px;}
.face {height: 120px;width: 135px;background-color: white;border-radius: 120px 120px 90px 90px;position: relative;bottom: 40px}
.earl, .earr {background-color: #4d4449;height: 40px;width: 45px;border-radius: 43px 43px 0 0;}
.earl {transform: rotate(-38deg);-webkit-transform: rotate(-38deg);position: relative;top: 31px;right: 2px}
.earr {transform: rotate(38deg);-webkit-transform: rotate(38deg);position: relative;bottom: 8px;left: 96px}
.panda {position: relative;bottom: 190px;left: 100px;}
.eyel, .eyer {background-color: #4d4449;height: 35px;width: 32px;border-radius: 32px;position: relative;}
.eyel {top: 5px;left: 22px;transform: rotate(-20deg);-webkit-transform: rotate(-20deg);}
.eyer {transform: rotate(20deg);-webkit-transform: rotate(20deg);bottom: 30.5px;left: 80px;}
.blshl, .blshr {background-color: #ff9999;height: 16px;width: 22px;border-radius: 50%;position: relative;opacity: 0.8;}
.blshl {transform: rotate(25deg);-webkit-transform: rotate(25deg);position: relative;top: 64px;left: 17px;}
.blshr {transform: rotate(-25deg);-webkit-transform: rotate(-25deg);position: relative;top: 46px;left: 95px;}
.eyeball1, .eyeball2 {height: 10px;width: 10px;background-color: white;border-radius: 50%;position: relative;transition: 1s all;-webkit-transition: 1s all;}
.eyeball1 {left: 10px;top: 10px;transform: rotate(20deg);-webkit-transform: rotate(20deg);}
.eyeball2 {left: 10px;top: 10px;transform: rotate(-20deg);-webkit-transform: rotate(-20deg)}
.nose {height: 17px;width: 17px;background-color: #4d4449;border-radius: 20px 0px 4px;transform: rotate(45deg);-webkit-transform: rotate(45deg);position: relative;bottom: 30px;left: 60px}
.line {background-color: #4d4449;height: 10px;width: 2px;position: relative;transform: rotate(-45deg);-webkit-transform: rotate(-45deg);left: 18.5px;top: 15px;}
.m, .mm {background-color: #4d4449;height: 8px;width: 15px;border-radius: 0 0 8px 8px;position: relative;}
.m {bottom: 21px;left: 53px;}
.m1 {height: 6px;width: 13px;background-color: white;border-radius: 0 0 10px 10px;position: relative;left: 1px;bottom: 1px;}
.mm {bottom: 30px;left: 68.5px;}
.handl, .handr {background-color: #4d4449;height: 45px;width: 35px;border-radius: 10px 10px 30px 30px;z-index: 2;transition: 1s all;-webkit-transition: 1s all;}
.handl {position: relative;bottom: 140px;left: 50px}
.handr {position: relative;bottom: 185px;left: 250px}
.pawl, .pawr {background-color: #4d4449;height: 50px;width: 50px;border-radius: 40px 40px 20px 20px;position: relative;z-index: 2;}
.pawl {top: 210px;left: 80px;}
.pawr {top: 160px;left: 200px;}
.p1 {background-color: white;height: 25px;width: 30px;position: relative;top: 21px;left: 10px;border-radius: 25px 25px 10px 10px;}
.p2, .p3, .p4 {background-color: white;height: 10px;width: 10px;border-radius: 50%;position: relative;}
.p2 {bottom: 13px;left: 9.5px}
.p3 {bottom: 17.2px;right: 2.5px;}
.p4 {bottom: 27.2px;left: 22px;}
input {color: #4d4449;width: 180px;border: none;outline: none;border-bottom: 1px solid #4d4449;font-family: 'Ubuntu', sans-serif;}
.fa {color: #4d4449;font-size: 20px;outline: none}
.loginButton {background-color: #ffadad;outline: none;border: none;color: #000;font-family: 'Ubuntu', sans-serif;font-size: 16px;padding: 8px 20px;position: relative;border-radius: 8px;width: 100%;margin-left: -15px;max-width: 160px;left: 50%;right: 50%;transform: translate(-50%);}
.body-inner {position: relative;width: 360px;margin: 80px auto 0;background-color: white;padding: 20px;border-radius: 10px;box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);animation: slideDown 2s ease-in-out;opacity: 0;animation-fill-mode: forwards;height: 480px;}
input.inputFields{background: none;border: none;color: #333;font-size: 1.1rem;font-weight: 600;line-height: 1;outline: none;}
@keyframes slideDown {
    0% {transform: translateY(-100px);opacity: 0; }
    100% {transform: translateY(0);opacity: 1;}
}
button.btn-link{margin: 10px auto}
button.btn-link a{text-decoration: underline;color: blue;}
button.btn-link a:hover{color:red}
.forgot-pswd{text-align: center;margin-left: -22px;}
.spinner {border: 2px solid #f3f3f3;border-top: 2px solid #3498db;border-radius: 50%;width: 16px;height: 16px;animation: spin 1s linear infinite;display: inline-block;}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
