.agent-listing {font-family: Arial, sans-serif;}  
.header {display: flex;justify-content: space-between;align-items: center;margin-bottom: 16px;padding: 0 20px;}  
.header h2 {font-size: 1.25rem;font-weight: bold;margin: 0;}
.add-agent-btn {padding: 8px 16px;background-color: #000;color: #fff;border: none;border-radius: 4px;cursor: pointer;}
.add-agent-btn:hover {background-color: #232222;transform: scale(1.10);}
.agent-table td {padding: 8px;border-bottom: 1px solid #ddd;}
.agent-name {font-weight: bold;}
.actions {text-align: right;}
.action-btn {padding: 8px;margin-right: 8px;border: 1px solid #ddd;border-radius: 4px;background-color: #fff;color: #000;cursor: pointer;}
.action-btn.disabled {cursor: not-allowed;background-color: #f5f5f5;color: #ccc;}
.action-btn:hover:not(.disabled) {background-color: #e6e6e6;}
  